<template>
  <div class="spt">
    <div>
      <div class="spt-header mb-header">
        <h2>SIM PHONG THUỶ</h2>
        <div class="w-100 header cat center-c" v-if="vw>992">
          <div class="bg"></div>
          <div class="hcontent center-form">
            <div class="center-title">
              <h3 style="text-align: left;">Nhập thông tin để chọn <br> SIM hợp tuổi</h3>
            </div>
            <div class="form">
              <div class="row pt-4">
                <div class="col-lg-3 col-md-12 text">
                  Ngày sinh (<font color="red">*</font>)
                </div>
                <div class="col-4 col-lg-3">
                  <v-select :clearable="false" v-model="day" :options="range(1,32)"></v-select>
                </div>
                <div class="col-4 pl-0 col-lg-3">
                  <v-select :clearable="false" v-model="month" :options="range(1,13)"/>
                </div>
                <div class="col-4 pl-0 col-lg-3">
                  <v-select :clearable="false" v-model="year" :options="range(1950,2024).sort((b, a) =>  a - b )"/>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-lg-3 col-sm-4 text">
                  Giờ sinh (<font color="red">*</font>)
                </div>
                <div class="col-lg-9 col-sm-12">
                  <v-select v-model="hour" :options="hours"/>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-lg-3 col-4 text">
                  Giới tính (<font color="red">*</font>)
                </div>
                <div class="col-lg-2 col-4">
                  <b-form-radio name="gender" value="1" v-model="gender" class="mt-lg-2">Nam</b-form-radio>
                </div>
                <div class="col-lg-2 col-4">
                  <b-form-radio name="gender" value="2" v-model="gender" class="mt-lg-2">Nữ</b-form-radio>
                </div>
              </div>
              <button class="btn btn-primary btntuoi mb-3 h-center-btn" @click="chonsim(0)">
                <div><img src="@/assets/images/simphongthuy/left-flower.png" /></div>
                <div>
                  CHỌN SIM HỢP TUỔI
                </div>
                <div><img src="@/assets/images/simphongthuy/right-flower.png" /></div>
              </button>
              <div class="block-divide" />
              <h4 style="margin-top: 14px; margin-bottom: 14px; border: none">Chấm điểm phong thuỷ số điện thoại</h4>
              <!-- <img role="button" @click="$router.push({ name: 'diem-phong-thuy', query: $route.query})"
                   src="@/assets/images/simphongthuy/btn2.png" width="415"/> -->
              <button class="btn btn-primary btntuoi mt-0" style="text-transform: uppercase" @click="$router.push({ name: 'diem-phong-thuy', query: $route.query})">Chấm điểm phong thủy</button>
            </div>
          </div>
        </div>
        <div class="w-100 header1" v-if="vw<=992">
          <div class="bg" style="border-radius: 6px;"></div>
          <div class="hcontent">
            <h3 style="text-align: left;">Nhập thông tin<br> để chọn SIM hợp tuổi</h3>
            <div class="form">
              <div class="row pt-3">
                <div class="col-lg-3 col-md-12 text pb-1">
                  Ngày sinh (<font color="red">*</font>)
                </div>
                <div class="col-4 col-lg-3">
                  <v-select :clearable="false" v-model="day" :options="range(1,32)"></v-select>
                </div>
                <div class="col-4 pl-0 col-lg-3">
                  <v-select :clearable="false" v-model="month" :options="range(1,13)"/>
                </div>
                <div class="col-4 pl-0 col-lg-3">
                  <v-select :clearable="false" v-model="year" :options="range(1923,2031)"/>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-lg-3 col-sm-4 text pb-1">
                  Giờ sinh (<font color="red">*</font>)
                </div>
                <div class="col-lg-9 col-sm-12">
                  <v-select v-model="hour" :options="hours"/>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-lg-3 col-4 text">
                  Giới tính (<font color="red">*</font>)
                </div>
              </div>
              <div class="row pt-1">
                <div class="col-lg-2 col-5 text-left">
                  <b-form-radio name="gender" value="1" v-model="gender" class="mt-lg-2">Nam</b-form-radio>
                </div>
                <div class="col-lg-2 col-5 text-left">
                  <b-form-radio name="gender" value="2" v-model="gender" class="mt-lg-2">Nữ</b-form-radio>
                </div>
              </div>
              <button class="btn btn-primary btntuoi mt-3" @click="chonsim(0)">CHỌN SIM HỢP TUỔI</button>
            </div>
          </div>
        </div>
        <div class="card mt-2 mb-card">
          <div class="car-body row">
            <div class="col-12" style="padding-left: 21px; padding-right: 21px;">
              <h4 style="margin-bottom: 14px; border: none">Chấm điểm phong thuỷ <br/>số điện thoại</h4>
              <button class="btn btn-primary mobile-btn-get-point" @click="$router.push({ name: 'diem-phong-thuy', query: $route.query})"
                style="width: 100%; text-transform: uppercase;">Chấm điểm phong thủy</button>
            </div>
          </div>
        </div>
      </div>
      <div class="spt-content" v-if="vw>992">
        <div class="cheader mb-4">
          <img src="@/assets/images/simphongthuy/stick2.png"/>
          <h2>DANH MỤC SIM PHONG THỦY</h2>
          <img src="@/assets/images/simphongthuy/stick2.png"/>
        </div>
        <div class="text-center">
          <div role="button" @click="chonsim(i.id)" class="loc" v-for="i in sim_types" :key="i.id">{{ i.text }}</div>
        </div>
        <div class="text-center">
          <div role="button" class="loc" @click="chonsim(i.id,'menh')" v-for="i in menhs" :key="i.id">{{ i.text }}</div>
        </div>
      </div>
      <div class="spt-content" v-if="vw<=992">
        <div class="cheader mb-4" ref="sim_list">
          <img src="@/assets/images/simphongthuy/stick3.png"/>
          <h2 class="pt-sim-list">DANH MỤC SIM PHONG THỦY</h2>
          <img src="@/assets/images/simphongthuy/stick3.png"/>
        </div>
        <div class="h-scroll-container">
          <div class="h-scroll-container__scroll">
            <div class="h-scroll-container__show">
              <div role="button" @click="chonsim(i.id)" class="loc" v-for="i in sim_types" :key="i.id">{{ i.text }}</div>
            </div>
            <div class="h-scroll-container__show">
              <div role="button" class="loc" @click="chonsim(i.id,'menh')" v-for="i in menhs" :key="i.id">{{ i.text }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="spt-content pt-4 pb-4 mt-4 mb-4 cc2">
        <div class="row c1">
          <div class="col-4" style="display: flex; justify-content: center;"><img src="@/assets/images/simphongthuy/Sim thần tài 4.png" style="width: 80%; height: fit-content;"/></div>
          <div class="col-8">
            <h3>SIM PHONG THỦY MANG Ý NGHĨA GÌ?</h3>
            <p>Vạn vật từ khi sinh ra đều mang con số đặc trưng với những ý nghĩa riêng biệt. Việc mỗi người biết tận
              dụng con số gắn liền với bản mệnh của mình sẽ dễ dàng có được sự an lành, tốt đẹp và đại cát trong cả cuộc
              sống lẫn phát triển sự nghiệp của bản thân.
            </p>
            <p><span class="font-weight-bold">Sim phong thủy là dãy số</span> cân bằng âm dương, ngũ hành tương sinh,
              quẻ dịch phù hợp với mục đích của người dùng dựa vào bát tự ngày sinh và giờ sinh. Sim phong thủy sẽ tốt
              hơn khi có con số thu hút vượng khí, điểm phong thủy và tổng số nút cao. Sim phong thủy còn được coi là
              “vật phẩm phong thủy” hộ thân mang tới ý nghĩa tốt đẹp cho người sở hữu.
              <br>Chắc hẳn rất nhiều người trong số chúng ta băn khoăn không biết số điện thoại của mình có hợp với tuổi
              của mình không?
              <br>Dựa vào những thông tin khách hàng cung cấp iTel sẽ giúp bạn xem ý nghĩa phong thủy của số điện thoại
              nhanh chóng, chính xác. Ngoài ra iTel còn gợi ý danh sách những số điện thoại mang lại vượng khí, tài lộc,
              phù trợ công danh sự nghiệp thăng tiến.</p>
            <h4>Ý nghĩa sim phong thủy được luận theo các thuyết phong thủy nào?</h4>
            <p>Để nắm bắt được ý nghĩa sim phong thủy là điều không hề đơn giản. Ý nghĩa của sim phong thủy chỉ được thể
              hiện đầy đủ nhất khi được luận giải theo các tiêu chí sau:
              <br>Người dùng muốn chọn được dãy sim phong thủy chuẩn xác thì phải bình giải số điện thoại theo 04 yếu tố
              và dựa vào bát tự ngày sinh để đánh giá:
            </p>
            <ul>
              <li><b>• Ngũ hành sinh mệnh</b>: Trong ngũ hành có tương sinh, có tương khắc. Khi chọn sim cần lấy sim có
                ngũ hành tương sinh, tương hỗ với bản mệnh chủ nhân. Bên cạnh đó cần đảm bảo yếu tố bổ khuyết tứ trụ,
                tức là ngũ hành của sim bổ khuyết cho ngũ hành tứ trụ (ngày, giờ, tháng, năm sinh) của người dùng sim
              </li>
              <li><b>• Tổng số nút cao</b>: Số nút cao thì sim có nhiều vượng khí. Do đó, khi chọn sim cần xem tổng điểm
                dãy số. Bên cạnh đó, số điện thoại có sự xuất hiện của các cặp số đẹp lại càng thêm tốt
              </li>
              <li><b>• Vân Cát – Hung</b>: Dựa vào 81 linh số để tra cứu được số điện thoại là Cát hay Hung, ý nghĩa đại
                cát mang lại nguồn năng lượng cát khí mạnh mẽ mọi việc hanh thông
              </li>
              <li><b>• Hành quẻ bát quái</b>: Khi xem bói sim theo kinh dịch, nếu quẻ dịch của số điện thoại là quẻ cát
                thì dãy số sẽ được đánh giá cao hơn về mặt phong thủy
              </li>
            </ul>
            <p>Nếu chấm điểm sim theo 4 yếu tố trên đều cho kết quả tốt thì đây là sim phong thủy lý tưởng, mang tới mọi
              điều tốt lành cho chủ nhân</p>
            <h4>Hướng dẫn chọn sim phong thủy hợp tuổi:</h4>
            <p><b>Cách 1: Chọn sim phong thủy hợp tuổi</b></p>
            <p>Nhập thông tin ngày/tháng/năm sinh, giờ sinh và giới tính
              <br>Nhấp chọn sim hợp tuổi
              <br>Hệ thống sẽ đưa ra danh sách các số điện thoại dựa theo ngày tháng năm sinh sắp xếp điểm phong thủy
              giảm dần và cách thức đặt mua sim dễ dàng.
            </p>
            <p><b>Cách 2: Chọn sim phong thủy theo danh mục</b></p>
            <p>Khách hàng biết mình thuộc mạng nam mệnh Thổ chọn “Sim hợp mệnh Thổ”
              <br>Khách hàng là người kinh doanh mong muốn có nhiều tài lộc để công việc kinh doanh ngày càng phát triển
              chọn “Sim Tài lộc”
              <br>Nhập thông tin ngày/tháng/năm sinh, giờ sinh và giới tính
              <br>Hệ thống sẽ đưa ra danh sách các số điện thoại dựa theo ngày tháng năm sinh sắp xếp điểm phong thủy
              giảm dần và cách thức đặt mua sim dễ dàng.
            </p>
            <p><b>Cách 3: Chấm điểm phong thủy</b></p>
            <p>Nhập Số điện thoại
              <br>Nhập thông tin ngày/tháng/năm sinh, giờ sinh và giới tính
              <br>Hệ thống dựa trên các thông tin của khách hàng để luận giải 4 yếu tố có phù hợp về mặt phong thủy với
              chủ nhân hay không. Từ đó đưa ra các gợi ý về số điện thoại khác phù hợp hơn, mang lại nhiều may mắn và
              tài lộc.
            </p>
          </div>
        </div>
        <div class="c2">
          <div>
            <h3>SIM PHONG THỦY<br/>MANG Ý NGHĨA GÌ?</h3>
            <p>Với ý nghĩa đem đến những điều tốt lành, sức khỏe, tài lộc và may mắn vật phẩm phong thủy từ xưa đến nay luôn gắn liền với cuộc sống của người Việt. Với ý nghĩa đem đến những điều tốt lành, sức khỏe, tài lộc và may mắn vật phẩm phong thủy từ xưa đến nay luôn gắn liền với cuộc sống của người Việt.Với ý nghĩa đem đến những điều tốt lành, sức khỏe, tài lộc và may mắn vật phẩm phong thủy từ xưa đến nay.
            </p>
            <p>
              Luôn gắn liền với cuộc sống của người Việt.Với ý nghĩa đem đến những điều tốt lành, sức khỏe, tài lộc và may mắn vật phẩm phong thủy từ xưa đến nay luôn gắn liền với cuộc sống của người Việt.Luôn gắn liền với cuộc sống của người Việt.Với ý nghĩa đem đến những điều tốt lành, sức khỏe, tài lộc và may mắn vật phẩm phong thủy từ xưa đến nay luôn gắn liền với cuộc sống của người Việt Luôn gắn liền với cuộc sống của người Việt.Với ý nghĩa đem đến những điều tốt lành, sức khỏe, tài lộc và may mắn vật phẩm phong thủy từ xưa đến nay luôn gắn liền với cuộc sống của người Việt
            </p>
          </div>
          <img src="@/assets/images/simphongthuy/Sim thần tài 41.png" width="100%"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Navigation, Pagination} from "swiper";
import {SwiperCore, Swiper, SwiperSlide} from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import {
  BFormInput, BFormGroup, BButton, BFormCheckbox, BFormRadio
} from "bootstrap-vue";
// Import Swiper styles
import "swiper/swiper-bundle.css";
import Select2 from 'v-select2-component';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import CryptoJS from "crypto-js";
import {createHash} from 'crypto';

SwiperCore.use([Navigation, Pagination]);

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
    require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

export default {
  components: {
    Swiper,
    SwiperSlide,
    BFormInput,
    BFormGroup, BButton,
    BFormCheckbox,
    BFormRadio,
    Select2,
    "v-select": vSelect
  },
  data() {
    return {
      iframeSrc: '',
      phone: '',
      year: 1986,
      month: 1,
      day: 1,
      hour: 'Tý - Từ 23 giờ đến 1 giờ sáng',
      gender: '1',
      sim_types: [
        {id: 1, text: 'Sim Gia đạo tình duyên', type: 'loc'},
        {id: 2, text: 'Sim Đại cát', type: 'loc'},
        {id: 3, text: 'Sim Công danh', type: 'loc'},
        {id: 4, text: 'Sim Tài lộc', type: 'loc'},
      ],
      cart_info: {
        shipment_type: "home",
        _track: "T_" + Math.random().toString(36).slice(2),
      },
      menhs: [
        {id: 1, text: 'Sim hợp mệnh Kim'},
        {id: 2, text: 'Sim hợp mệnh Mộc'},
        {id: 3, text: 'Sim hợp mệnh Thủy'},
        {id: 4, text: 'Sim hợp mệnh Hỏa'},
        {id: 5, text: 'Sim hợp mệnh Thổ'}
      ],
      vw: window.innerWidth,
      hours: ['Tý - Từ 23 giờ đến 1 giờ sáng', 'Sửu - Từ 1 giờ đến 3 giờ sáng', 'Dần - Từ 3 giờ đến 5 giờ sáng', 'Mão - Từ 5 giờ đến 7 giờ sáng', 'Thìn - Từ 7 giờ đến 9 giờ sáng', 'Tỵ - Từ 9 giờ đến 11 giờ sáng', 'Ngọ - Từ 11 giờ đến 13 giờ trưa', 'Mùi - Từ 13 giờ đến 15 giờ trưa', 'Thân - Từ 15 giờ đến 17 giờ chiều', 'Dậu - Từ 17 giờ đến 19 giờ tối', 'Tuất - Từ 19 giờ đến 21 giờ tối', 'Hợi - Từ 21 giờ đến 23 giờ tối',]
    };
  },
  watch: {
    $route(to, from) {
      console.log('a');
      this.$bvModal.show('modal-vietlott');
    },
  },
  created() {

  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted() {
    console.log(this.$route.query);
    this.tracking(localStorage.trackId, 0);
    window.insider_object.page = {
        type: "mua-sim-phong-thuy",
    };
  },
  methods: {
    chonsim(type, menh) {
      const key="Everchosen"
      if (menh) {
        const encryptedGender =CryptoJS.AES.encrypt(this.gender,key).toString()
        const encryptedDob =  CryptoJS.AES.encrypt(this.day + "/" + this.month + "/" + this.year,key).toString()
        this.$router.push({
          name: "sim-phong-thuy-boloc",
          query: {
            gender: encryptedGender,
            hour: this.hour,
            menh: 1,
            type: type,
            day: encryptedDob,
            cart_info: JSON.stringify(this.cart_info),
          }
        });
      } else {
        // console.log("gender",this.gender)
        const encryptedGender = CryptoJS.AES.encrypt(this.gender,"Everchosen").toString()
         const encryptedDob =  CryptoJS.AES.encrypt(this.day + "/" + this.month + "/" + this.year,"Everchosen").toString()
        // console.log(this.day + "/" + this.month + "/" + this.year)
        // console.log("query",encryptedGender,encryptedDob)
        this.$router.push({
          name: "sim-phong-thuy-boloc",
          query: {
            gender: encryptedGender,
            hour: this.hour,
            type: type,
            day: encryptedDob,
            cart_info: JSON.stringify(this.cart_info),
          }
        });
      }
    },
    range(start, end) {
      var count = end - start;
      if (arguments.length == 1) {
        count = start;
        start = 0;
      }

      var foo = [];
      for (var i = 0; i < count; i++) {
        foo.push(start + i);
      }
      return foo;
    },
    Img(pic) {
      return images[pic];
    },
    tracking(id_, step) {
      var id = id_;
      if (!id_) {
        localStorage.trackId = Math.random().toString(36).slice(2);
        id = localStorage.trackId;
      }
      const parsedParams = this.$route.query;
      this.$route.hash
          .split("&")
          .map((part) => part.replace(/^#/, ""))
          .forEach((param) => {
            const parts = param.split("=");
            parsedParams[parts[0]] = parts[1];
          });
      useJwt
          .post("tracking/" + id + "/" + step, {
            cart_info: this.cart_info,
            cart: this.cart,
            query: parsedParams,
          })
          .then((response) => {
            //console.log(response.data.result)
          });
    },
  },
};
</script>
<style lang="scss">
.h-center-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}
.center-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.block-divide {
  width: 73%;
  margin: auto;
  height: 1px;
  background: red;
}

.center-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-form {
  background: white;
  width: 48% !important;
  padding: 23px !important;
  border-radius: 17px !important;
}

.spt .header {
  position: relative;
  z-index: 1;
  height: 590px;
  margin-top: 30px;
}

.spt .header1 {
  position: relative;
  z-index: 1;
  min-height: 390px;
  margin-top: 30px;
}

.spt .header .hcontent {
  padding: 41px;
  text-align: center;
  width: 100%;
}

.spt .header1 .hcontent {
  padding: 21px;
  text-align: center;
  width: 100%;
}

.btntuoi {
  border-radius: 28.7953px !important;
  height: 60px;
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
}

.spt .header .btntuoi {
  border-radius: 28.7953px !important;
  height: 60px;
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
  width: 400px;
}

.spt .header1 .btntuoi {
  border-radius: 28.7953px !important;
  height: 60px;
  font-weight: 700;
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
}

.spt .header .hcontent .form .text {
  line-height: 30px;
  font-weight: 700;
  font-size: 15px;;
}

.spt .header1 .hcontent .form .text-left {
  font-weight: 700;
  font-size: 15px;;
}

.spt .header1 .hcontent .form .text {
  text-align: left;
  line-height: 20px;
  font-weight: 700;
  font-size: 15px;;
}

.spt .header .hcontent .form h4, .spt .header1 .hcontent .form h4 {
  font-weight: 800;
  font-size: 19px;
  line-height: 35px;
  margin-top: 20px;
  border-top: 2px solid #ED1F24;
  margin-left: auto;
  margin-right: auto;
  width: 360px;
}

@media (min-width: 992px) {
  .spt .header .hcontent .form {
    width: 60%;
  }
  .spt .center-c .hcontent .form {
    width: 100%;
  }
  .spt .spt-header h2 {
    color: #424242;
    width: 50%;
    text-align: left;
  }
  .spt .spt-content .c2 {
    display: none;
  }
  .spt .spt-header .card {
    display: none;
  }
  .spt .header1 {
    display: none;
  }
}

@media (max-width: 992px) {
  .h-scroll-container {
    padding-inline: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  .h-scroll-container__scroll {
    width: calc(100% - 40px);
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .h-scroll-container__show {
    display: flex;
    flex-wrap: nowrap;
    width: max-content;
  }

  .h-scroll-container__show div {
    display: flex;
    flex-wrap: nowrap;
    width: max-content;
  }

  .mb-card {
    padding: 20px;
  }

  .mobile-btn-get-point {
    border-radius: 30.229px !important;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 112.7%;
    text-transform: uppercase;
    width: 100%;
    background-image: url('~@/assets/images/simphongthuy/btn-mb-bg.svg');
    border: 3px solid yellow !important;
  }

  .mb-header h2 {
    color: white !important;
    text-align: center !important;
    font-family: Roboto !important;
    font-size: 17px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    height: 42px;
    background: #EC2226;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% + 30px) !important;
    transform: translateX(-15px);
  }
  .btntuoi {
    height: 45px;
  }
  .spt .header1 .btntuoi {
    height: 45px;
  }
  .spt .spt-header .card img {
    width: 100% !important;
  }
  .spt .spt-header .card h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #373737;
  }
  .spt .header.cat {
    display: none;
  }
  .spt .spt-content .loc {
    height: 35px !important;
    line-height: 35px !important;
    padding: 0 10px !important;
    font-weight: 600;
    font-size: 13.9242px !important;
    display: inline-block;
    margin-right: 5px !important;
    margin-top: 5px !important;
  }
  .spt .spt-header {
    margin-right: 15px;
    margin-left: 15px;
    width: auto !important;
  }
  .spt .header .hcontent .form, .spt .header1 .hcontent .form {
    width: 100%;
  }
  .spt .header .hcontent .form h4, .spt .header1 .hcontent .form h4 {
    width: auto !important;
  }
  .spt .header .hcontent .form-control, .spt .header1 .hcontent .form-control {
    margin-top: 10px;
  }
  .spt .spt-header h2 {
    color: #424242;
    width: 100%;
    text-align: center;
  }
  .spt .header .hcontent .form .text {
    line-height: 22px !important
  }
  .spt .header img, .spt .header .btntuoi, .spt .header1 img {
    max-width: 100%;
  }
  .spt .header1 .btntuoi {
    max-width: 100% !important;
  }
  .spt .spt-content .cheader img {
    display: none !important
  }
  .spt .spt-content .cheader h2 {
    width: 100% !important;
  }
  .spt .spt-content .c1 {
    display: none;
  }
  .spt .spt-content.cc2 {
    position: relative;
    margin-top: 0 !important;
  }
  .spt .spt-content .c2 > div {
    padding: 20px;
  }
  .spt .spt-content .c2 > div h3 {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #3E3E3E;
  }
  .spt .spt-content .c2 h4 {
    font-weight: 800;
    font-size: 15px;
    line-height: 2;
    color: #3E3E3E;
  }
  .spt .spt-content .c2 > div p, .spt .spt-content .c2 > div li {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
    padding-top: 10px;
  }
  .spt .spt-content .c2 {
    background: #FFF4EC;
    border-radius: 13.4277px;
    margin: 0 15px;
    width: calc(100vw - 30px);
  }
}

.spt .header .hcontent .form {
  max-width: 500;
  margin-left: auto;
  margin-right: auto;
}

.spt .header .hcontent h3, .spt .header1 .hcontent h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #000000;
}

.spt .header .bg {
  background: url('~@/assets/images/simphongthuy/bgtop.png') no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #E5E5E5;
  opacity: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.15));
}

.spt .header1 .bg {
  background: url('~@/assets/images/simphongthuy/mobile-bg.png') no-repeat;
  background-size: 100% 100%;
  // background: white;
  width: calc(100% + 30px);
  height: 100%;
  border-radius: 0 !important;
  border: 1px solid #E5E5E5;
  opacity: 1;
  position: absolute;
  top: -50px;
  bottom: 0;
  left: -15px;
  right: 0;
  z-index: -1;
  border: none;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.15));
}

.spt .header1 .hcontent {
  background: white;
  margin-top: 50px;
  border-radius: 6px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

.spt {
  max-width: 122.4rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.spt .spt-content .loc {
  color: #fff;
  background: #ED1F24;
  border-radius: 34.809px;
  height: 45px;
  line-height: 45px;
  padding: 0 16px;
  font-weight: 600;
  font-size: 17.9242px;
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
}

.spt .spt-content .c1 h3 {
  font-weight: 800;
  font-size: 32px;
  line-height: 132.69%;
  //display: flex;
  align-items: center;
  color: #3E3E3E;
}

.spt .spt-content .c1 h4 {
  font-weight: 700;
  font-size: 26px;
  line-height: 132.69%;
  //display: flex;
  align-items: center;
  color: #3E3E3E;
}

.spt .spt-content .c1 li {
  padding-top: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  //display: flex;
  align-items: center;
  padding-left: 20px;
}

.spt .spt-content .c2 li {
  padding-top: 10px;
  line-height: 1.5;
  padding-left: 20px;
}

.spt .spt-content .c1 p {
  padding-top: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  //display: flex;
  align-items: center;

  color: #000000;
}

.spt .spt-content .cheader {
  margin-top: 40px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.spt .spt-content .cheader h2 {
  width: 40%;
  display: inline-block;
  color: #000000
}

.spt .spt-content.c2 .box2:hover {
  border: 1px solid #E9182C;
  border-radius: 6.53271px;
  transform: scale(1.001);
}

.spt .spt-content .cheader img {
  width: 25%;
  display: inline-block;
  width: 161px;
  margin-bottom: 12px;
}

.spt .spt-content h2 {
  font-weight: 700;
  font-size: 26px;
  line-height: 132.69%;
  align-items: center;
  text-align: center;
}

.spt .spt-header, .spt .spt-content {
  width: 100%;
}

.spt .spt-header h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 69px;
  color: #424242;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.spt .vs__dropdown-toggle {
  border-radius: 1rem;
  height: 5rem !important;
}

.spt .vs__actions {
  padding: 4px 16px 0px 3px;
}

.spt .vs__selected-options {
  padding: 3px 3px 3px 3px;
}

.spt .vs__selected-options span {
  font-size: 2rem;
  font-weight: 500;
}

.vs__search, .vs__search:focus, .spt .phone {
  font-size: 2rem !important;
  font-weight: 500 !important;
}
</style>